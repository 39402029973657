import React, { lazy, Suspense, useEffect } from 'react'
import './index.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { trackPageView } from './config/analytics'
import Loader from './components/Loader'


const Home = lazy(() => import("./pages/Home"))
const NewsnUpdate = lazy(() => import("./pages/NewsnUpdate"))
const Autobot = lazy(() => import("./pages/Autobot"))
const CbtLink = lazy(() => import("./pages/CbtLink"))
const Services = lazy(() => import("./pages/Services"))
const WhoWeAre = lazy(() => import("./pages/WhoWeAre"))
const SuppportPage = lazy(() => import("./pages/SuppportPage"))
const AppDev = lazy(() => import("./pages/AppDev"))
const Support = lazy(() => import("./pages/Support"))
const NetServices = lazy(() => import("./pages/NetServices"))
const TelecomServices = lazy(() => import("./pages/TelecomServices"))
const NewsDetailsPage = lazy(() => import("./pages/NewsDetailsPage"))
const PageNotFound = lazy(() => import("./pages/PageNotFound"))



const App = () => {

  useEffect(() => {
    trackPageView();
  }, []);
  
  return (
    <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
             <Route path='/' element={<Home />} />
             <Route path='/services' element={<Services />} />
             <Route path='/appDev' element={<AppDev />} />
             <Route path='/support' element={<Support />} />
             <Route path='/netServices' element={<NetServices />} />
             <Route path='/telecomServices' element={<TelecomServices />} />
             <Route path='/whoWeAre' element={<WhoWeAre />} />
             <Route path='/news-update' element={<NewsnUpdate />} />
             <Route path='/newsDetailsPage' element={<NewsDetailsPage />} />
             <Route path='/suppportPage' element={<SuppportPage />} />
             <Route path='/document/auto-bot' element={<Autobot />} />
             <Route path='/cbt-link' element={<CbtLink />} />
             <Route path="*" Component={PageNotFound} />
          </Routes>
        </Suspense>
    </BrowserRouter>
  )
}

export default App

